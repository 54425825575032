var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[(_vm.match)?_c('div',{staticClass:"mb-5"},[_c('VCheckbox',{staticClass:"py-0 my-0",attrs:{"label":_vm.match.description,"disabled":_vm.disabled,"readonly":_vm.readonly,"hide-details":""},model:{value:(_vm.isMatch),callback:function ($$v) {_vm.isMatch=$$v},expression:"isMatch"}})],1):_vm._e(),_c('VRow',{attrs:{"dense":""},on:{"input":_vm.onChange}},[_c('VCol',{attrs:{"cols":"12","md":"4"}},[_c('InputCountryDadata',{attrs:{"label":"Страна","disabled":_vm.isMatch || _vm.disabled,"readonly":_vm.readonly,"rules":_vm.rules},on:{"select":function (ref) {
            var country_iso_code = ref.country_iso_code;

            _vm.fields.countryIsoCode = country_iso_code;
          }},model:{value:(_vm.fields.country),callback:function ($$v) {_vm.$set(_vm.fields, "country", $$v)},expression:"fields.country"}})],1),_c('VCol',{attrs:{"cols":"12","md":"4"}},[_c('InputRegionDadata',{attrs:{"label":"Регион","locations":[{
            country_iso_code: _vm.fields.countryIsoCode,
          }],"disabled":_vm.isMatch || _vm.disabled,"readonly":_vm.readonly,"rules":_vm.rules},on:{"select":function (ref) {
            var region_iso_code = ref.region_iso_code;

            _vm.fields.regionIsoCode = region_iso_code;
          }},model:{value:(_vm.fields.region),callback:function ($$v) {_vm.$set(_vm.fields, "region", $$v)},expression:"fields.region"}})],1),_c('VCol',{attrs:{"cols":"12","md":"4"}},[_c('VTextField',{attrs:{"disabled":_vm.isMatch || _vm.disabled,"readonly":_vm.readonly,"rules":_vm.rules,"label":"Индекс","outlined":"","dense":"","hideDetails":"auto"},model:{value:(_vm.fields.zipCode),callback:function ($$v) {_vm.$set(_vm.fields, "zipCode", $$v)},expression:"fields.zipCode"}})],1),_c('VCol',{attrs:{"cols":"12"}},[_c('InputCityDadata',{attrs:{"label":"Населенный пункт","locations":[{
            country_iso_code: _vm.fields.countryIsoCode,
            region_iso_code: _vm.fields.regionIsoCode,
          }],"disabled":_vm.isMatch || _vm.disabled,"readonly":_vm.readonly,"rules":_vm.rules},model:{value:(_vm.fields.city),callback:function ($$v) {_vm.$set(_vm.fields, "city", $$v)},expression:"fields.city"}})],1),_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VTextField',{attrs:{"disabled":_vm.isMatch || _vm.disabled,"readonly":_vm.readonly,"rules":_vm.rules,"label":"Улица (пр-кт Мира, ул.Мира, пер.Мира, туп.Мира)","outlined":"","dense":"","hideDetails":"auto"},model:{value:(_vm.fields.street),callback:function ($$v) {_vm.$set(_vm.fields, "street", $$v)},expression:"fields.street"}})],1),_c('VCol',{attrs:{"cols":"12","md":"2"}},[_c('VTextField',{attrs:{"disabled":_vm.isMatch || _vm.disabled,"readonly":_vm.readonly,"rules":_vm.rules,"label":"Дом (д.10)","outlined":"","dense":"","hideDetails":"auto"},model:{value:(_vm.fields.house),callback:function ($$v) {_vm.$set(_vm.fields, "house", $$v)},expression:"fields.house"}})],1),_c('VCol',{attrs:{"cols":"12","md":"2"}},[_c('VTextField',{attrs:{"disabled":_vm.isMatch || _vm.disabled,"readonly":_vm.readonly,"label":"Корпус/Строение (корп.2, стр.3)","outlined":"","dense":"","hideDetails":"auto"},model:{value:(_vm.fields.building),callback:function ($$v) {_vm.$set(_vm.fields, "building", $$v)},expression:"fields.building"}})],1),_c('VCol',{attrs:{"cols":"12","md":"2"}},[_c('VTextField',{attrs:{"disabled":_vm.isMatch || _vm.disabled,"readonly":_vm.readonly,"label":"Квартира/Помещение (кв.4, пом.5)","outlined":"","dense":"","hideDetails":"auto"},model:{value:(_vm.fields.flat),callback:function ($$v) {_vm.$set(_vm.fields, "flat", $$v)},expression:"fields.flat"}})],1),(_vm.previous)?_c('VCol',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-muted"},[_c('small',[_vm._v("Предыдущее значение: "+_vm._s(_vm.previous))])])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }