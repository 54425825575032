<template>
  <div :class="$style.root">
    <div v-if="match" class="mb-5">
      <VCheckbox class="py-0 my-0" :label="match.description" :disabled="disabled" :readonly="readonly" v-model="isMatch" hide-details />
    </div>
    <VRow dense @input="onChange">
      <VCol cols="12" md="4">
        <InputCountryDadata
            label="Страна"
            v-model="fields.country"
            :disabled="isMatch || disabled"
            :readonly="readonly"
            :rules="rules"
            @select="({ country_iso_code }) => {
              fields.countryIsoCode = country_iso_code;
            }"
        />
      </VCol>
      <VCol cols="12" md="4">
        <InputRegionDadata
            label="Регион"
            v-model="fields.region"
            :locations="[{
              country_iso_code: fields.countryIsoCode,
            }]"
            :disabled="isMatch || disabled"
            :readonly="readonly"
            :rules="rules"
            @select="({ region_iso_code }) => {
              fields.regionIsoCode = region_iso_code;
            }"
        />
      </VCol>
      <VCol cols="12" md="4">
        <VTextField
            v-model="fields.zipCode"
            :disabled="isMatch || disabled"
            :readonly="readonly"
            :rules="rules"
            label="Индекс"
            outlined
            dense
            hideDetails="auto"
        />
      </VCol>
      <VCol cols="12">
        <InputCityDadata
            label="Населенный пункт"
            v-model="fields.city"
            :locations="[{
              country_iso_code: fields.countryIsoCode,
              region_iso_code: fields.regionIsoCode,
            }]"
            :disabled="isMatch || disabled"
            :readonly="readonly"
            :rules="rules"
        />
      </VCol>
      <VCol cols="12" md="6">
        <VTextField
            v-model="fields.street"
            :disabled="isMatch || disabled"
            :readonly="readonly"
            :rules="rules"
            label="Улица (пр-кт Мира, ул.Мира, пер.Мира, туп.Мира)"
            outlined
            dense
            hideDetails="auto"
        />
      </VCol>
      <VCol cols="12" md="2">
        <VTextField
            v-model="fields.house"
            :disabled="isMatch || disabled"
            :readonly="readonly"
            :rules="rules"
            label="Дом (д.10)"
            outlined
            dense
            hideDetails="auto"
        />
      </VCol>
      <VCol cols="12" md="2">
        <VTextField
            v-model="fields.building"
            :disabled="isMatch || disabled"
            :readonly="readonly"
            label="Корпус/Строение (корп.2, стр.3)"
            outlined
            dense
            hideDetails="auto"
        />
      </VCol>
      <VCol cols="12" md="2">
        <VTextField
            v-model="fields.flat"
            :disabled="isMatch || disabled"
            :readonly="readonly"
            label="Квартира/Помещение (кв.4, пом.5)"
            outlined
            dense
            hideDetails="auto"
        />
      </VCol>
      <VCol v-if="previous" cols="12">
        <div class="text-muted">
          <small>Предыдущее значение: {{previous}}</small>
        </div>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { get, forEach, debounce } from 'lodash-es';
import InputCountryDadata from '@/components/general/InputCountryDadata/InputCountryDadata';
import InputRegionDadata from '@/components/general/InputRegionDadata/InputRegionDadata';
import InputCityDadata from '@/components/general/InputCityDadata/InputCityDadata';

export default {
  name: 'Address',
  components: {
    InputCountryDadata,
    InputRegionDadata,
    InputCityDadata,
  },
  props: {
    required: { type: Boolean },
    value: { type: Object },
    match: { type: Object },
    previous: { type: String },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
  },
  data() {
    return {
      isMatch: false,
      fields: {
        country: '',
        region: '',
        zipCode: '',
        city: '',
        street: '',
        house: '',
        building: '',
        flat: '',
        countryIsoCode: '',
        regionIsoCode: '',
      }
    }
  },
  computed: {
    diff() {
      return JSON.stringify(this.value) !== JSON.stringify(get(this.match, ['values', 0]));
    },
    rules() {
      if (this.required) return [(v) => !!v || 'Обязательно для заполнения'];
      return [];
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.fields);
    },
  },
  watch: {
    isMatch: {
      handler(value) {
        if (value) {
          forEach(get(this.match, ['values', 0]), (value, key) => {
            this.fields[key] = value;
          });
          this.onChange();
        }
      }
    },
    match: {
      deep: true,
      handler: debounce(function() {
        if (this.isMatch) {
          forEach(get(this.match, ['values', 0]), (value, key) => {
            this.fields[key] = value;
          });
          this.onChange();
        }
      }, 300),
    },
    value: {
      immediate: true,
      handler(value) {
        forEach(value, (value, key) => {
          this.fields[key] = value;
        });
      },
    },
  },
  mounted() {
    this.isMatch = !this.diff;
  },
}
</script>

<style module lang="scss">
.root{}
</style>
