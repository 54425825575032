var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('div',{class:_vm.$style.header},[_vm._v(_vm._s(_vm.field['label']))]),_c('div',{staticClass:"mt-5"},[_c('VTextField',{attrs:{"label":"Поиск по названию","outlined":"","dense":"","hideDetails":"auto","clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),(_vm.message)?_c('VAlert',{staticClass:"mt-5 mb-0",attrs:{"type":"error","outlined":"","dense":""}},[_vm._v(_vm._s(_vm.message))]):_vm._e(),_vm._l((_vm.items),function(ref){
var options = ref.options;
var title = ref.title;
return _c('div',{key:title,class:_vm.$style.item},[(Object.keys(_vm.items).length > 1 && title)?_c('div',{class:_vm.$style.title},[_vm._v(_vm._s(title))]):_vm._e(),_c('VRow',_vm._l((options),function(ref){
var text = ref.text;
var value = ref.value;
return _c('VCol',{key:value,attrs:{"cols":"12","md":"3","sm":"6"}},[_c('div',{staticClass:"d-flex"},[_c('VTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('VCheckbox',{staticClass:"mt-0 pt-0",attrs:{"value":value,"onIcon":"mdi-email","offIcon":"mdi-email","hideDetails":""},model:{value:(_vm.subscribe['values']),callback:function ($$v) {_vm.$set(_vm.subscribe, 'values', $$v)},expression:"subscribe['values']"}})],1)]}}],null,true)},[(!~_vm.subscribe['values'].indexOf(value))?_c('span',[_vm._v("Подписаться на оповещения")]):_c('span',[_vm._v("Отписаться от оповещений")])]),_c('VCheckbox',{staticClass:"mt-0 pt-0",attrs:{"value":value,"label":text,"hideDetails":""},model:{value:(_vm.field['values']),callback:function ($$v) {_vm.$set(_vm.field, 'values', $$v)},expression:"field['values']"}})],1)])}),1)],1)}),(_vm.message)?_c('VAlert',{staticClass:"mb-0",attrs:{"type":"error","outlined":"","dense":""}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }